import React from 'react';
import { useForm, ValidationError } from '@formspree/react';

function FormspreeContactForm() {

  const [state, handleSubmit] = useForm("xrgnkbvz");

  if (state.succeeded) {
    return <p>Thanks for signing up!</p>;
  }

  return (
    <form className="p-4" onSubmit={handleSubmit}>
      <input
        id="email"
        type="email"
        name="email"
        placeholder="Enter Email"
        className="block p-4 w-full text-white placeholder-white bg-white/10 rounded-lg border border-gray-300/10 sm:text-md focus:ring-blue-100/10 focus:border-blue-100/10 dark:bg-white/10 dark:border-white/20 dark:placeholder-white dark:text-white dark:focus:ring-blue-100/10 dark:focus:border-blue-100/10 backdrop-blur"
      />
      <ValidationError
        prefix="Email"
        field="email"
        errors={state.errors}
      />
      <button
        className="w-full text-white bg-white/20 border border-white/20 focus:outline-none hover:bg-white/10 focus:ring-4 focus:ring-white/20 font-medium rounded-lg text-lg px-6 py-3.5 mr-2 mt-4 mb-2 dark:bg-white/20 dark:text-white dark:border-white/20 dark:hover:bg-white/10 dark:hover:border-white/40 dark:focus:ring-white/40"
        type="submit"
        disabled={state.submitting}
      >
        Sign Up
      </button>
    </form>
  );
};

export const contactForm = FormspreeContactForm;